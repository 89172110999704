<template>
    <v-app>
        <loading v-if="loading"/>
        <div v-if="!loading" style="position: relative;">
            <v-img :src="$url+item.coverImageUrl" lazy-src="@/assets/images/slider1.png" class="head"/>
            <div style="position: absolute; top: 80%; left: 0; width: 100%; height: 20%; background: rgba(0, 0, 0, 0.5);"></div>
            <span style="position: absolute; top: 90%; left: 85%; transform: translate(-50%, -50%); color: white;">
                <h3>{{ item.name }}</h3>
                {{ item.shortDescription }}
            </span>
        </div>
        <v-container fluid>
            <div v-html="item.content">
            </div>
        </v-container>
    </v-app>
</template>
<style scoped>
.head {
    width: 100%;
    height: 600px;
    display: block;
}
</style>
<script>
export default {

    data() {
        return {
            loading: false,
            item: {}
        }
    },

    created() {
        this.getItem()
    },

    methods: {
        async getItem() {
            this.loading = true
            try {
                let res = await this.$http.get(`Clinic/${this.$route.params.id}`)
                this.item = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
